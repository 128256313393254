import { useLocation } from "@remix-run/react";
import { useEffect } from "react";

type Props = {
  active: boolean;
};

declare global {
  interface Window {
    dataLayer: Array<IArguments>;
    gtag(...args: any): void;
  }
}
let gtagInstalled = false;

export default function GA({ active }: Props) {
  const { pathname } = useLocation();
  const gaTrackingId = import.meta.env.VITE_GA_TRACKING_ID;

  useEffect(() => {
    if (!active || gtagInstalled) return;
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`;
    document.body.append(script);
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
      window.dataLayer.push(arguments);
    };
    window.gtag("js", new Date());
    window.gtag("config", gaTrackingId, { send_page_view: false });
    gtagInstalled = true;
  }, [active]);

  useEffect(() => {
    if (!active) return;
    window.gtag("event", "page_view");
  }, [pathname, active]);

  return null;
}

import { Transition } from "@headlessui/react";
import { twJoin } from "tailwind-merge";

type Props = {
  show?: boolean;
  onChange: (value?: number) => void;
};

export default function CookieBar(props: Props) {
  return (
    <Transition
      show={props.show}
      as="div"
      enterFrom="translate-y-1/4 opacity-0"
      leaveTo="translate-y-1/4 opacity-0 "
      className={twJoin(
        "fixed inset-x-4 bottom-4 z-50",
        "container flex w-fit flex-col gap-6",
        "lg:flex-row lg:gap-10",
        "text-xs text-white",
        "rounded-xl bg-black/90 p-6 backdrop-blur",
        "_ease-in-out transition-all",
      )}
    >
      <div className="max-w-2xl">
        <p>
          We use cookies to enhance your browsing experience and provide you
          with the best possible service. Our website uses{" "}
          <b>Essential Cookies</b>, which are necessary for the proper
          functioning of the site, and <b>Analytical Cookies</b>, which help us
          understand how you interact with our content. By continuing to use our
          website, you consent to the use of <b>Essential Cookies</b> and have
          the option to disable <b>Analytical Cookies</b>.
        </p>
      </div>
      <div className="mx-auto flex w-fit flex-wrap items-center gap-4 lg:ml-auto">
        <button
          className="button my-0 min-w-40 justify-center bg-teal-500 hover:bg-teal-300"
          onClick={() => props.onChange(1)}
        >
          Accept All
        </button>
        <button
          className="button my-0 min-w-40 justify-center border-2 border-white bg-black hover:bg-white hover:text-black"
          onClick={() => props.onChange(0)}
        >
          Only Essentials
        </button>
      </div>
    </Transition>
  );
}
